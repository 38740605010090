import $ from 'jquery';
import AOS from 'aos';

const general = {
    handleMap: function() {

        let houses = $('#js-house-coordinates');

        if (!houses.length) {
            return;
        }

        try {
            houses = JSON.parse(houses.text());

            let script = document.createElement('script');
            let map;

            script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDGV7jt1WO4RojRUgbnwZz-gaQPVe36xwY&callback=initMap';
            script.defer = true;

            window.initMap = function() {

                const mapCenterPoint = { lat: parseFloat(houses[0].lat), lng: parseFloat(houses[0].lon) };
                // The map, centered at Uluru
                map = new google.maps.Map(document.getElementById("map"), {
                    zoom: houses.length > 1 ? 10 : 12,
                    center: mapCenterPoint,
                    disableDefaultUI: true,
                });

                var markers = [];
                var windows = [];

                Object.keys(houses).forEach($key => {
                    markers[$key] = new google.maps.Marker({
                        position: new google.maps.LatLng(parseFloat(houses[$key].lat), parseFloat(houses[$key].lon)),
                        map: map,
                        icon: {
                            url: '/wp-content/themes/intrepide/static/img/intrepide/remax_balloon.png',
                            scaledSize: new google.maps.Size(75, 75)
                        }
                    });

                    if (houses.length) {
                        let content = '';

                        content += '<div class="ltn__product-item ltn__product-item-4 text-center---">';

                        if (houses[$key]['thumbnail']) {
                            content += '<div class="product-img"><a href="' + houses[$key]['link'] + '"><img src="'+ houses[$key]['thumbnail'] + '" title="' + houses[$key]['title'] +' "></a>';

                            content += '<div class="product-badge"><ul><li class="sale-badge bg-blue"> ' + houses[$key]['transaction'] + '</li></ul></div>';
                            content += '<div class="product-img-location-gallery">';

                            if (houses[$key]['location']) {
                                content += '<div class="product-img-location"><ul><li><a href="' + houses[$key]['link'] + '#property-location"><i class="flaticon-pin"></i> ' + houses[$key]['location'] + '</a></li></ul></div>'
                            }

                            content += '<div class="product-img-gallery"><ul>';

                            if (houses[$key]['nbPictures']) {
                                content += ' <li><a href="' + houses[$key]['link'] + '"><i class="fas fa-camera"></i> ' + houses[$key]['nbPictures'] + '</a></li>'    ;
                            }

                            if (houses[$key]['nbVideo']) {
                                content += ' <li><a href="' + houses[$key]['link'] + '"><i class="fas fa-film"></i> 1</a></li>'    ;
                            }

                            content += '</ul></div></div></div>';
                        }

                        content += '<div class="product-info"><div class="product-price">';

                        if (houses[$key]['price']) {
                            content += '<span>'+ houses[$key]['price'] + '</span>';
                        }

                        content += '<span>' + houses[$key]['status'] + '</span>';
                        content += '</div>'

                        content += '<h3 class="product-title"><a href="' + houses[$key]['link'] + '">' + houses[$key]['title'] + '</a></h3>';
                        content += '<ul class="ltn__list-item-2 ltn__list-item-2-before">';

                        if (houses[$key]['bedrooms']) {
                            content += '<li><span>' + houses[$key]['bedrooms'] + ' <i class="flaticon-bed"></i></span>' + houses[$key]['bedrooms_label'] + '</li>';
                        }

                        if (houses[$key]['bathrooms']) {
                            content += '<li><span>' + houses[$key]['bathrooms'] + ' <i class="flaticon-bed"></i></span>' + houses[$key]['bathrooms_label'] + '</li>';
                        }

                        if (houses[$key]['living_space']) {
                            content += '<li><span>' + houses[$key]['living_space'] + ' <i class="flaticon-bed"></i></span> m²</li>';
                        }

                        if (!houses[$key]['bedrooms'] && !houses[$key]['bathrooms'] && !houses[$key]['living_space']) {
                            if (houses[$key]['total_area']) {
                                content += '<li><span>' + houses[$key]['total_area'] + ' <i class="flaticon-bed"></i></span> ' + houses[$key]['total_area_label'] + '</li>';
                            }
                        }

                        content += '</ul></div>';
                        content += '<div class="product-info-bottom">';

                        content += '<div class="real-estate-agent">';

                        if ( houses[$key]['agent']['thumbnail']) {
                            content += '<div class="agent-img"><a href="' + houses[$key]['agent']['link'] + '"><img src="' + houses[$key]['agent']['thumbnail'] + '" alt="' + houses[$key]['agent']['title'] + '" ></a></div>';
                        }

                        content += '<div class="agent-brief"> <h4 class="mb-0">  <a href="' + houses[$key]['agent']['link'] + '">' + houses[$key]['agent']['title'] + '</a></h4>';

                        if ( houses[$key]['agent']['position']) {
                            content += '<small>' + houses[$key]['agent']['position'] + '</small>';
                        }

                        content += '</div></div></div>';

                        content += '<div class="product-hover-action"><div class="ltn__blog-btn">';
                        content += '<a href="' + houses[$key]['link'] + '" title="' + houses[$key]['see_more'] + '" class="text-red">' + houses[$key]['see_more'] + '</a>';
                        content += '</div></div></div>';

                        windows[$key] = new google.maps.InfoWindow({
                            content: content,
                            ariaLabel: houses[$key]['title'],
                        });

                        markers[$key].addListener("click", () => {
                            windows[$key].open({
                                anchor: markers[$key],
                                map,
                            });
                        });


                    }
                });

                const styledMapType = new google.maps.StyledMapType(
                    [
                        {
                            "featureType": "all",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#003da5"
                                }
                            ]
                        },
                        {
                            "featureType": "all",
                            "elementType": "labels",
                            "stylers": [
                                {
                                    "visibility": "simplified"
                                }
                            ]
                        },
                        {
                            "featureType": "all",
                            "elementType": "labels.text",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": "84"
                                },
                                {
                                    "gamma": "7.96"
                                },
                                {
                                    "weight": "0.01"
                                }
                            ]
                        },
                        {
                            "featureType": "all",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "gamma": 0.01
                                },
                                {
                                    "lightness": 20
                                }
                            ]
                        },
                        {
                            "featureType": "all",
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "saturation": -31
                                },
                                {
                                    "lightness": -33
                                },
                                {
                                    "weight": 2
                                },
                                {
                                    "gamma": 0.8
                                }
                            ]
                        },
                        {
                            "featureType": "all",
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.country",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.country",
                            "elementType": "labels",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "lightness": 30
                                },
                                {
                                    "saturation": 30
                                }
                            ]
                        },
                        {
                            "featureType": "landscape",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "saturation": 20
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "lightness": 20
                                },
                                {
                                    "saturation": -20
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "lightness": 10
                                },
                                {
                                    "saturation": -30
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "saturation": 25
                                },
                                {
                                    "lightness": 25
                                }
                            ]
                        },
                        {
                            "featureType": "transit.line",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "transit.line",
                            "elementType": "labels.text",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "transit.station",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "lightness": -20
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "visibility": "simplified"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "labels",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        }
                    ]
                );

                map.mapTypes.set("styled_map", styledMapType);
                map.setMapTypeId("styled_map");
            }

            document.head.appendChild(script);
        } catch (err) {
            return;
        }
    },
    switchFilters() {
        $('.js-switcher-filter').on('change', function() {
            if (this.checked) {
                $('.js-price-filter').addClass('d-none');

                $('.js-price-filter[data-value="'+this.value+'"]').removeClass('d-none');
            }
        }).trigger('change');
    },
    rot13: function (str){
        var input     = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        var output    = 'NOPQRSTUVWXYZABCDEFGHIJKLMnopqrstuvwxyzabcdefghijklm';
        var index     = x => input.indexOf(x);
        var translate = x => index(x) > -1 ? output[index(x)] : x;
        return str.split('').map(translate).join('');
    },
    uncryptRot13: function(){
        const cryptedElements = document.querySelectorAll('#js-rot13');
        cryptedElements.forEach(el => {
            el.href = 'mailto:' + general.rot13(el.innerHTML)
            el.innerHTML = general.rot13(el.innerHTML)
        });
    },
    uncryptRot13Spe: function(){
        const cryptedElements = document.querySelectorAll('#js-rot13-spe');
        cryptedElements.forEach(el => {
            const href = el.href.replace(`mailto:${el.dataset.mail}`, `mailto:${general.rot13(el.dataset.mail)}`);
            el.href = href;
            
        });
    },
}
export default general;

(function($){
    $(document).ready(function(){
        general.handleMap();
        general.switchFilters();
        general.uncryptRot13();
        general.uncryptRot13Spe();
        AOS.init();
    });
})($);

(function($) {
    $(document).ready(function() {
        
        var typeSelect = $('#filter_type');   
        var sizeSelect = $('#filter_size');
        var priceSelect = $('#filter_price');
        var bedroomSelect = $('#filter_bedroom');
        typeSelect.change(function() {
            if (typeSelect.val() === 'Terrain') {
              
                sizeSelect.prop('disabled', true);
                priceSelect.prop('disabled', true);
                bedroomSelect.prop('disabled', true);
            } else {
               
                sizeSelect.prop('disabled', false);
                priceSelect.prop('disabled', false);
                bedroomSelect.prop('disabled', false);
            }
        });
        typeSelect.trigger('change');
    });
})(jQuery);
